export default {
  data() {
    return {
      chartsOptionsYoutube: [
        {
          value: "upload_volume",
          label: "Volumen de Subida",
        },
        {
          value: "upload_volume_time_order",
          label: "Volumen de subida (Orden de Tiempo)",
        },
        {
          value: "likes_volume",
          label: "Likes",
        },
        {
          value: "comments_volume",
          label: "Comentarios",
        },
        {
          value: "likes_per_view",
          label: "Likes/vista",
        },
        {
          value: "likes_per_view_time_order",
          label: "Likes/vista (Orden de Tiempo)",
        },
        {
          value: "comments_per_view",
          label: "Comentarios/vista",
        },
        {
          value: "comments_per_country",
          label: "Comentarios por país",
        },
        {
          value: "comments_per_country_time_order",
          label: "Comentarios por país (Orden de Tiempo)",
        },
      ],
      chartsOptionsTwitter: [
        {
          value: "retweet_count",
          label: "Retweets",
        },
        {
          value: "reply_count",
          label: "Respuestas",
        },
        {
          value: "like_count",
          label: "Likes",
        },
      ],
    };
  },
};
