<template>
  <div class="avigail-home">
    <NavigationBar />
    <main class="avigail-home__main-content">
      <div class="selector">
        <p>Selecciona una plataforma</p>
        <el-select v-model="selectedSource" @change="updateChartsOptions">
          <el-option v-for="(option, index) in sourceOptions" :key="index" :label="option.label"
            :value="option.value"></el-option>
        </el-select>
      </div>
      <el-alert v-if="!selectedSource" title="Seleciona una plataforma para ver los gráficos" type="info">
      </el-alert>
      <div v-if="selectedSource">
        <div class="selector">
          <FilterInput @filter-applied="filterApplied"></FilterInput>
          <p>Selecciona una serie</p>
          <el-select v-model="selectedSeries">
            <el-option v-for="(option, index) in seriesOptions" :key="index" :label="option.label"
              :value="option.value"></el-option>
          </el-select>
          <p>Selecciona una gráfica</p>
          <el-select v-model="selectedChart">
            <el-option v-for="(option, index) in chartsOptionsArr" :key="index" :label="option.label"
              :value="option.value"></el-option>
          </el-select>
          <el-button type="warning" @click="fetchChartData">Generar gráfica</el-button>
        </div>
      </div>
    </main>
    <ChartView />
  </div>
</template>

<script>
// ------------------- Vuex Imports ------------------- //
import { mapActions, mapMutations, mapState } from "vuex";

// ------------------- Utils Imports ------------------- //
import chartsOptions from "../../utils/chartsOptions";
import seriesOptions from "../../utils/seriesOptions";
import sourceOptions from "../../utils/sourceOptions";

// ------------------- Component Imports ------------------- //
import NavigationBar from '../../components/NavigationBar/Index.vue';
import ChartView from './components/ChartsView/Index.vue';
import FilterInput from "./components/FilterInput/Index.vue";

export default {
  name: 'ChartSelector',
  mixins: [chartsOptions, seriesOptions, sourceOptions],
  components: {
    NavigationBar,
    ChartView,
    FilterInput,
  },

  data() {
    return {
      selectedSeries: null,
      selectedChart: null,
      selectedSource: null,
      filter: null,
      chartsOptionsTwitter: chartsOptions.data().chartsOptionsTwitter,
      chartsOptionsYoutube: chartsOptions.data().chartsOptionsYoutube,
      chartsOptionsArr: [],
    };
  },

  computed: {
    ...mapState('loading', ['loading']),
  },

  methods: {
    ...mapActions('analysis', ['getAnalysis', 'fetchAnalysisResult']),
    ...mapMutations('analysis', ['setAnalysisResult', 'setSelectedSeries', 'setSelectedChart']),
    ...mapMutations('loading', ['setLoading']),

    async fetchChartData() {
      if (!this.selectedSeries || !this.selectedChart) {
        this.$message({
          type: 'warning',
          message: 'Selecciona una series y una grafica.',
        });
        return;
      }
      // Set the state of the loading to true
      this.setLoading(true);
      try {
        const RESULT = await this.fetchAnalysisResult({
          filter: this.filter,
          series: this.selectedSeries,
          source: this.selectedSource,
          chartType: this.selectedChart,
        });
        this.$store.commit('analysis/setAnalysisResult', RESULT);
        this.$store.commit('analysis/setSelectedSeries', this.selectedSeries);
        this.$store.commit('analysis/setSelectedChart', this.selectedChart);
        // Set the result of the analysis in the store and is pass to the component that shows the chart
        // The component that shows the chart is a child of this component
        // Emit the event to show the chart
        this.$root.$emit('changeShowChart', true);
      } catch (error) {
        this.$message({
          type: 'error',
          message: 'Ocurrió un error al obtener la gráfica.',
        });
      } finally {
        // Set the state of the loading to false after the chart is shown
        this.setLoading(false);
      }
    },

    filterApplied(payload) {
      this.filter = payload;
    },

    updateChartsOptions() {
      if (this.selectedSource === 'youtube') {
        this.chartsOptionsArr = this.chartsOptionsYoutube;
      } else if (this.selectedSource === 'twitter') {
        this.chartsOptionsArr = this.chartsOptionsTwitter;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.avigail-home {
  width: 100vw;
  height: 100vh;
  display: flex;
  grid-template-columns: auto 1fr;
  grid-template-rows: 100vh;

  &__main-content {
    display: block;
    margin-top: 1em;
    margin-left: 1em;
  }

  overflow: hidden;
}

p {
  margin: 0;
}

.el-alert {
  margin-top: 1em;
  width: 100%;
  height: 3em;
}

.el-select-dropdown__item.selected {
  background-color: #FDF6EC;
  border-color: #F5DAB1;
  color: #E6A23C;
  font-weight: 700;
}

.el-range-editor.is-active,
.el-range-editor.is-active:hover,
.el-select .el-input.is-focus .el-input__inner {
  border-color: #F5DAB1 !important;
}

.el-select, .el-button, .filter {
  width: 100%;
  margin-bottom: 1em;
}
</style>
