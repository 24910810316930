<template>
  <div class="tools-bar__item">
    <label class="label">
      Filtros
    </label>
    <el-popover placement="bottom" trigger="click" v-model="visible">
      <div class="close-container">
        <el-button size="mini" type="text" style="color: #c0c4cc" @click="closeContainerFilter">Cancelar</el-button>
      </div>
      <el-input slot="reference" suffix-icon="el-icon-arrow-down" size="small" readonly>
      </el-input>
      <div class="filters-container">
        <!-- Author filter -->
        <div class="filter">
          <label for="filter__select" class="label filter__label"> Autor: </label>
          <el-input placeholder="Buscar" ref="search" @keyup.enter.native="filterDocuments" v-model="author" type="text"
            size="small" class="filter__input">
            <span slot="suffix" v-show="author" @click="clearSearchInput('author')">
              <i class="el-input__icon el-icon-circle-close el-input__clear" style="cursor: pointer"></i>
            </span>
          </el-input>
        </div>
        <!-- Author Location filter -->
        <div class="filter">
          <label for="filter__select" class="label filter__label"> Ubicación del autor: </label>
          <el-input placeholder="Buscar" ref="search" @keyup.enter.native="filterDocuments" v-model="authorLocation"
            type="text" size="small" class="filter__input">
            <span slot="suffix" v-show="authorLocation" @click="clearSearchInput('authorLocation')">
              <i class="el-input__icon el-icon-circle-close el-input__clear" style="cursor: pointer"></i>
            </span>
          </el-input>
        </div>
        <!-- Receiver Location filter -->
        <div class="filter">
          <label for="filter__select" class="label filter__label"> Ubicación del receptor: </label>
          <el-input placeholder="Buscar" ref="search" @keyup.enter.native="filterDocuments" v-model="receiverLocation"
            type="text" size="small" class="filter__input">
            <span slot="suffix" v-show="receiverLocation" @click="clearSearchInput('receiverLocation')">
              <i class="el-input__icon el-icon-circle-close el-input__clear" style="cursor: pointer"></i>
            </span>
          </el-input>
        </div>
        <!-- Country filter -->
        <div class="filter">
          <label for="filter__select" class="label filter__label"> País: </label>
          <el-select placeholder="Selecciona un País" ref="search" v-model="searchCountry" filtereable size="small"
            class="filter__input">
            <el-option v-for="item in countries" :key="item" :label="item" :value="item">
            </el-option>
          </el-select>
        </div>
        <!-- Criterion filter -->
        <div class="filter">
          <label for="filter__select" class="label filter__label"> Criterio: </label>
          <el-select placeholder="Selecciona un Criterio" ref="search" v-model="searchCriterion" size="small"
            class="filter__input">
            <el-option v-for="(item, index) in criterions" :key="index" :label="item" :value="item">
            </el-option>
          </el-select>
        </div>
        <!-- Query filter -->
        <div class="filter">
          <label for="filter__select" class="label filter__label"> Consulta: </label>
          <el-input placeholder="Buscar" ref="search" @keyup.enter.native="filterDocuments" v-model="searchQuery"
            type="text" size="small" class="filter__input">
            <span slot="suffix" v-show="searchQuery" @click="clearSearchInput('searchQuery')">
              <i class="el-input__icon el-icon-circle-close el-input__clear" style="cursor: pointer"></i>
            </span>
          </el-input>
        </div>
        <!-- Text filter -->
        <div class="filter">
          <label for="filter__select" class="label filter__label"> Texto: </label>
          <el-input placeholder="Buscar" ref="search" @keyup.enter.native="filterDocuments" v-model="text" type="text"
            size="small" class="filter__input">
            <span slot="suffix" v-show="text" @click="clearSearchInput('text')">
              <i class="el-input__icon el-icon-circle-close el-input__clear" style="cursor: pointer"></i>
            </span>
          </el-input>
        </div>
        <!-- Discard filter -->
        <div class="filter">
          <label for="filter__select" class="label filter__label"> Desechar: </label>
          <el-select placeholder="Selecciona True o False" ref="search" v-model="discard" size="small"
            class="filter__input">
            <el-option :key="''" :label="''" :value="''"> </el-option>
            <el-option :key="'true'" :label="'True'" :value="'true'"> </el-option>
            <el-option :key="'false'" :label="'False'" :value="'false'"> </el-option>
          </el-select>
        </div>
        <!-- Reviewed filter -->
        <div class="filter">
          <label for="filter__select" class="label filter__label"> Revisado: </label>
          <el-select placeholder="Selecciona True o False" ref="search" v-model="reviewed" size="small"
            class="filter__input">
            <el-option :key="''" :label="''" :value="''"> </el-option>
            <el-option :key="'true'" :label="'True'" :value="'true'"> </el-option>
            <el-option :key="'false'" :label="'False'" :value="'false'"> </el-option>
          </el-select>
        </div>
        <!-- Historical Memory filter -->
        <div class="filter">
          <label for="filter__select" class="label filter__label"> Memoria Histórica: </label>
          <el-select placeholder="Selecciona True o False" ref="search" v-model="historicalMemory" size="small"
            class="filter__input">
            <el-option :key="''" :label="''" :value="''"> </el-option>
            <el-option :key="'true'" :label="'True'" :value="'true'"> </el-option>
            <el-option :key="'false'" :label="'False'" :value="'false'"> </el-option>
          </el-select>
        </div>
        <!-- Political Education filter -->
        <div class="filter">
          <label for="filter__select" class="label filter__label"> Educación política: </label>
          <el-select placeholder="Selecciona True o False" ref="search" v-model="politicalEducation" size="small"
            class="filter__input">
            <el-option :key="''" :label="''" :value="''"> </el-option>
            <el-option :key="'true'" :label="'True'" :value="'true'"> </el-option>
            <el-option :key="'false'" :label="'False'" :value="'false'"> </el-option>
          </el-select>
        </div>
        <!-- Image Of Past  -->
        <div class="filter">
          <label for="filter__select" class="label filter__label"> Imagen del pasado: </label>
          <el-select placeholder="Selecciona True o False" ref="search" v-model="imageOfPast" size="small"
            class="filter__input">
            <el-option :key="''" :label="''" :value="''"> </el-option>
            <el-option :key="'true'" :label="'True'" :value="'true'"> </el-option>
            <el-option :key="'false'" :label="'False'" :value="'false'"> </el-option>
          </el-select>
        </div>
        <el-button type="warning" plain size="small" @click="filterDocuments" :loading="filterButtonLoadingAnimation">
          Filtrar
        </el-button>
      </div>
    </el-popover>
  </div>
</template>

<script>
// ------------------- Vuex Imports ------------------- //
import { mapState, mapActions } from "vuex";

// ------------------- Utils Imports ------------------- //
import filterOptions from "../../../../utils/filterOptions";

export default {
  name: 'FilterInput',
  mixins: [filterOptions],
  data() {
    return {
      // For filter
      author: null,
      authorLocation: null,
      receiverLocation: null,
      searchCountry: null,
      searchCriterion: null,
      searchQuery: null,
      text: null,
      discard: null,
      reviewed: null,
      historicalMemory: null,
      politicalEducation: null,
      imageOfPast: null,

      showLoadingAnimation: false,
      filterButtonLoadingAnimation: false,

      visible: false,
    };
  },
  computed: {
    ...mapState("documents", ["countries", "criterions", "docParams", "docFilterParams"]),
  },
  methods: {
    ...mapActions("documents", ["getFilterParams"]),

    async filterDocuments() {
      this.toggleLoadingAnimation(true);
      const PAYLOAD = this.preparePayload();
      this.$emit('filter-applied', PAYLOAD);
      this.toggleLoadingAnimation(false);
      this.visible = false;
    },
    async closeContainerFilter() {
      this.toggleLoadingAnimation(true);
      this.flagFilter = false;
      this.clearFilterInputs();
      const PAYLOAD = this.preparePayload();
      this.$emit('filter-applied', PAYLOAD);
      this.toggleLoadingAnimation(false);
      this.visible = false;
    },

    preparePayload() {
      const PAYLOAD = { ...this.docFilterParams };
      // Iterate through the payload properties
      Object.keys(PAYLOAD).forEach((key) => {
        // Check for null, undefined, or empty strings and remove them
        if (this[key] === null || this[key] === undefined || this[key] === '') {
          delete PAYLOAD[key];
        } else if (typeof this[key] === 'string' && (this[key].toLowerCase() === 'true' || this[key].toLowerCase() === 'false')) {
          PAYLOAD[key] = this[key].toLowerCase() === 'true'; // Check for boolean values and convert them to actual boolean
        } else {
          PAYLOAD[key] = this[key];
        }
      });
      console.log(PAYLOAD);
      return PAYLOAD;
    },

    toggleLoadingAnimation(state) {
      // Toggle the loading animation
      this.showLoadingAnimation = state;
      this.filterButtonLoadingAnimation = state;
    },

    async clearFilterInputs() {
      this.author = null;
      this.authorLocation = null;
      this.receiverLocation = null;
      this.searchCountry = null;
      this.searchCriterion = null;
      this.searchQuery = null;
      this.text = null;
      this.discard = null;
      this.reviewed = null;
      this.historicalMemory = null;
      this.politicalEducation = null;
      this.imageOfPast = null;
      const payload = this.docFilterParams;
      Object.keys(payload).forEach((key) => {
        payload[key] = this[key];
      });
      await this.getFilterParams(payload);
    },
  },
};
</script>

<style lang="scss" scoped>
.close-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: -10px;
  height: 30px;
}

.filters-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.filter {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-block-end: 5px;

  &__label {
    width: 120px;
  }

  &__input {
    width: 200px;
  }
}
</style>
