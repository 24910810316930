<template>
  <el-container v-if="showChart">
    <el-main v-loading="loading">
      <v-chart ref="chart" class="chart" :option="option" :key="showChart" />
    </el-main>
    <el-footer>
      <el-button @click="downloadChart" type="warning" plain>Descargar Gráfica</el-button>
    </el-footer>
  </el-container>
</template>

<script>
import { mapState } from "vuex";
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart } from "echarts/charts";
import { TitleComponent, TooltipComponent, LegendComponent } from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";

use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
]);

export default {
  name: "ChartView",
  data() {
    return {
      option: {},
      selectedChart: "",
      selectedSeries: "",
      showChart: false,
    };
  },

  components: {
    VChart,
  },

  provide: {
    [THEME_KEY]: "light",
  },

  watch: {
    // Watch for changes in analysisResult and update the option
    '$store.state.analysis.analysisResult': {
      handler: 'updateOption',
      immediate: true,
    },
    '$store.state.analysis.selectedChart': {
      handler: function updateSelectedChart(newVal) {
        this.updateProperty('selectedChart', newVal);
      },
      immediate: true,
    },
    '$store.state.analysis.selectedSeries': {
      handler: function updateSelectedSeries(newVal) {
        this.updateProperty('selectedSeries', newVal);
      },
      immediate: true,
    },
    '$store.state.analysis.loading': {
      handler: function updateLoading(newVal) {
        this.updateProperty('loading', newVal);
      },
      immediate: true,
    },
  },

  computed: {
    ...mapState('loading', ['loading']),
  },

  methods: {
    updateOption(newResult) {
      this.option = JSON.parse(JSON.stringify(newResult)) || {};
      this.showChart = true;
    },

    updateProperty(property, value) {
      this[property] = value;
    },

    downloadChart() {
      const CHART_INSTANCE = this.$refs.chart;

      if (CHART_INSTANCE) {
        const OPTIONS = {
          pixelRatio: 2,
          backgroundColor: '#fff',
        };

        const DATA_URL = CHART_INSTANCE.getDataURL(OPTIONS);

        const IMG = new Image();
        IMG.src = DATA_URL;

        const LINK = document.createElement('a');
        LINK.href = DATA_URL;
        LINK.download = `${this.selectedSeries}-${this.selectedChart}.png`;
        LINK.appendChild(IMG);
        LINK.target = '_blank';
        LINK.click();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.el-footer {
  text-align: right;
}
</style>
