export default {
  data() {
    return {
      sourceOptions: [
        {
          value: "youtube",
          label: "Youtube",
        },
        {
          value: "twitter",
          label: "Twitter",
        },
      ],
    };
  },
};
